<template>
  <div>
    <div class="info1">
      <div class="top1">
        <div class="leftTop1">
          <van-nav-bar left-text="" left-arrow @click-left="onClickLeft"/>
        </div>
        <div class="rightTop1">
          <span class="title1">《国战传说》账号注销</span>
        </div>
      </div>
      <van-form style="margin-left: 0.05rem;margin-top: -0.2rem">
        <div class="van-image">
          <img src="@/assets/common/unsubscribe.png" class="img">
        </div>
        <van-button class="completeForm" type="danger" @click="accounts">账号注销</van-button>
      </van-form>
    </div>
  </div>
</template>

<script>

import {NavBar} from "vant";
import Vue from 'vue';

Vue.use(NavBar);
import {

} from "@/api/common/user";
import {Base64} from "js-base64";

export default {
  data() {
    return {
      first_name:'',
      last_name:'',
      email:'',
      account: "", //账号
      password: "", //密码
      userInfo: undefined,
      url: 'https://play.google.com/store/games'
    };
  },
  created() {
    if (this.$route.query.key) {
      try {
        const jsonStr = Base64.decode(this.$route.query.key);
        this.userInfo = JSON.parse(jsonStr);
        if (this.userInfo.account === undefined || this.userInfo.password === undefined) {
          this.$toast.fail('参数错误！')
          this.content = false
          return false;
        }else {
          this.account =this.userInfo.account;
          this.password = this.userInfo.password;
        }
      } catch (error) {
        // 解析失败，说明不是有效的JSON格式
        this.$toast.fail('参数错误！')
        this.content = false
        return false;
      }
    } else {
      this.$toast.fail('请输入要注销的账号！')
      this.content = false
      return false;
    }
  },
  methods: {
    //  返回按键
    onClickLeft() {
      this.$router.push({
        path: '/deluser/gzcs/login'
      });
    },
    //进入注销页面
    accounts(){
      //  输入之后跳转到提示页面
      const key = Base64.encode(JSON.stringify({account: this.account, password: this.password}))
      this.$router.push({
        path: '/deluser/gzcs/confirm',
        query:{key: key}
      });
    }
  },
};
</script>

<style>
.info1 {
  margin: 0.2rem 0.2rem;
  border: 1px solid #333;
  height: 600px;
}

.top1 {
  font-size: 0.2rem;
  font-weight: bold;
  top: 10%;
  text-align: center;
  border-bottom: 2px solid #333;
  width: 100%;
  height: 0.4rem;
}

.leftTop1 {
  width: 10%;
  display: inline-block;
  font-size: large;
}

.rightTop1 {
  width: 90%;
  display: inline-block;
  margin-top: 0.1rem;
}

.title1 {
  font-weight: bold;
  font-size: 15px;
}

.van-nav-bar__left, .van-nav-bar__right {
  position: absolute;
  top: -13px;
  bottom: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 16px;
  font-size: 10rem;
  cursor: pointer;
}
.van-image {
  top: 25px;
  left: 5px;
}
.img {
  /* 可根据需要设置图片的样式 */
  max-width: 100%; /* 确保图片不超出容器宽度 */
  max-height: 100%; /* 确保图片不超出容器高度 */
}
.completeForm {
  width: 60%;
  height: 40px;
  border-color: #797979;
  background-color:white;
  left: 8%;
  font-size: 0.15rem;
  color: black;
}

.van-nav-bar .van-icon {
  color: black;
  font-size: x-large;
}

.van-nav-bar__content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}



</style>
